import { call, put, takeLatest } from 'redux-saga/effects'
import {
  deleteBannedUserListsSuccess,
  deleteBannedUserListsFailed,
  DELETE_BANNED_USER_START,
} from '../../actions/chat'
import { api } from '~/services/api'

/**
 * delete banned user api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const deleteWhiteListsApi = (params) => {
  const uri = `/chat-management/banned-user/${params}`
  return api.delete(uri)
}
/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doDeleteBannedUserLists(action) {
  try {
    const response = yield call(deleteWhiteListsApi, action?.payload)
    yield put(deleteBannedUserListsSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(deleteBannedUserListsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch delete banned user
 */
export default function* watchDeleteBannedUserLists() {
  yield takeLatest(DELETE_BANNED_USER_START, doDeleteBannedUserLists)
}
