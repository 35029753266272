import { Button, Modal, notification } from 'antd'
import { NOTIFICATION_DURATION } from '../constants'
import { useState } from 'react'
import { useChats } from '~/views/app/redux/hooks/useChat'
export default function DeleteBannedUserList(props) {
  const {
    t = {},
    title = null,
    action = {},
    dataBannedUserList,
    refreshData,
  } = props
  const [isLoadingDelete, setIsloadingDelete] = useState(false)

  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }
  const cancelButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    width: '50%',
    height: '48px',
    backgroundColor: 'transparent',
    border: 'none',
  }

  const { actions } = useChats()

  const handleSubmit = () => {
    try {
      setIsloadingDelete(true)
      actions?.deleteBannedUserLists(dataBannedUserList?._id, (res) => {
        if (res) {
          onSuccess()
          setIsloadingDelete(false)
        }
      })
    } catch (error) {
      setIsloadingDelete(false)
    }
  }

  const onSuccess = () => {
    notification.success({
      message: t('common.message.deleteUserSuccessfully'),
      placement: 'topRight',
      duration: NOTIFICATION_DURATION,
    })
    action?.handleCancel()
    refreshData()
  }

  return (
    <>
      <Modal
        centered
        title={
          <h3 className="flex justify-center text-xl font-normal text-[#fff]">
            {title ?? t('common.titleConfirmDeleteModal')}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[
          <div key="buttons" className="flex justify-center">
            <Button
              key="cancel"
              onClick={action?.handleCancel}
              style={cancelButtonStyle}
            >
              {t('cancel_sell.cancel')}
            </Button>
            <Button
              key="ok"
              type="primary"
              onClick={() => handleSubmit()}
              style={okButtonStyle}
              loading={isLoadingDelete}
            >
              {t('cancel_sell.ok')}
            </Button>
          </div>,
        ]}
      >
        <div className="text-center text-[16px]">
          {t('common.messConfirmDeleteModal')}
        </div>
      </Modal>
    </>
  )
}
