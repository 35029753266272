import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getListBannedUserListsSuccess,
  getListBannedUserListsFailed,
  GET_LIST_BANNED_USER_START,
} from '../../actions/chat'
import { api } from '~/services/api'

/**
 * get list banned user api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListBannedUserListsApi = (props) => {
  var uri = `/chat-management/banned-user`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetBannedUserLists(action) {
  try {
    const response = yield call(getListBannedUserListsApi, action?.payload)
    yield put(getListBannedUserListsSuccess(response))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(getListBannedUserListsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list banned user
 */
export default function* watchGetBannedUserLists() {
  yield takeLatest(GET_LIST_BANNED_USER_START, doGetBannedUserLists)
}
