import { addKeyToList } from '~/helpers/transform-data'
import {
  GET_LIST_BANNED_USER_START,
  GET_LIST_ADD_BANNED_USER_START,
  CREATE_BANNED_USER_START,
  DELETE_BANNED_USER_START,
  GET_LIST_BANNED_USER_SUCCESS,
  GET_LIST_ADD_BANNED_USER_SUCCESS,
  CREATE_BANNED_USER_SUCCESS,
  DELETE_BANNED_USER_SUCCESS,
  GET_LIST_BANNED_USER_FAILED,
  GET_LIST_ADD_BANNED_USER_FAILED,
  CREATE_BANNED_USER_FAILED,
  DELETE_BANNED_USER_FAILED,
} from '../actions/chat'

const initialState = {
  isLoadingGetListBannedUserList: false,
  isLoadingGetListAddBannedUserList: false,
  isLoadingCreate: false,
  isLoadingDelete: false,
  listBannedUser: [],
  isTongle: {},
  isChange: {},
}

/**
 * banned user reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function chatListsManagement(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_BANNED_USER_START:
      return {
        ...state,
        isLoadingGetListBannedUserList: true,
      }
    case GET_LIST_ADD_BANNED_USER_START:
      return {
        ...state,
        isLoadingGetListAddBannedUserList: true,
      }
    case CREATE_BANNED_USER_START:
      return {
        ...state,
        isLoadingCreate: true,
      }
    case DELETE_BANNED_USER_START:
      return {
        ...state,
        isLoadingDelete: true,
      }
    //

    case GET_LIST_BANNED_USER_SUCCESS:
      return {
        ...state,
        listBannedUser: addKeyToList(
          action?.payload?.data,
          action.payload?.currentPage,
          action.payload?.pageSize,
        ),
        total: action?.payload?.total,
        isLoadingGetListBannedUserList: false,
      }
    case GET_LIST_ADD_BANNED_USER_SUCCESS:
      return {
        ...state,
        listAddBannedUserList: action?.payload?.items,
        isLoadingGetListAddBannedUserList: false,
      }
    case CREATE_BANNED_USER_SUCCESS:
      return {
        ...state,
        isLoadingCreate: false,
      }
    case DELETE_BANNED_USER_SUCCESS:
      return {
        ...state,
        isLoadingDelete: false,
      }
    //

    case GET_LIST_BANNED_USER_FAILED:
      return {
        ...state,
        isLoadingGetListBannedUserList: true,
      }
    case GET_LIST_ADD_BANNED_USER_FAILED:
      return {
        ...state,
        isLoadingGetListAddBannedUserList: true,
      }
    case CREATE_BANNED_USER_FAILED:
      return {
        ...state,
        isLoadingCreate: false,
      }
    case DELETE_BANNED_USER_FAILED:
      return {
        ...state,
        isLoadingDelete: false,
      }

    default:
      return state
  }
}
