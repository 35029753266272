import { Button, Card, DatePicker, Popover, Row, Col, Switch, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { useTranslation } from '~/common/hooks/useTranslation'
import { itemsGameDashboard, levelKeys } from '~/common/constants'
import { getRandomColor } from '~/helpers/item-chart'
import { useDashboard } from '~/views/app/redux/hooks/useDashboard'
import moment from 'moment'
const { RangePicker } = DatePicker

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const GameChart = () => {
  const { t } = useTranslation()
  const { actions } = useDashboard()

  const [checkedList, setCheckedList] = useState([])
  const [date, setDate] = useState(['', ''])
  const [dataChart, setDataChart] = useState([])
  const labels = dataChart?.map((item) => {
    const formattedDate = item._id
    return formattedDate
  })

  const [activeMenuChild, setActiveMenuChild] = useState([])

  const [stateOpenKeys, setStateOpenKeys] = useState([])
  const verticalLinePlugin = {
    id: 'verticalLineOnHover',
    afterDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx = chart.ctx
        const x = chart.tooltip._active[0].element.x
        const yAxisTop = chart.scales.y.top
        const yAxisBottom = chart.scales.y.bottom

        ctx.save()
        ctx.beginPath()
        ctx.moveTo(x, yAxisTop)
        ctx.lineTo(x, yAxisBottom)
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)'
        ctx.setLineDash([5, 5])
        ctx.stroke()
        ctx.restore()
      }
    },
  }

  ChartJS.register(verticalLinePlugin)
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: true,
        labels: {
          color: '#fff',
          usePointStyle: true,
        },
      },
      borderDash: {
        xAxis: [0, 888],
      },
    },
    verticalLineOnHover: true,
    scales: {
      min: 0,
      y: {
        border: {
          color: '#fff',
        }, // for the grid lines
        grid: {
          display: true,
          color: 'rgba(255, 255, 255, 0.1)',
          tickBorderDash: [1, 10],
          tickColor: 'transparent',
          tickWidth: 2,
          offset: true,
          drawTicks: true,
          drawOnChartArea: true,
        },
        ticks: {
          color: '#fff',
          font: {
            size: 10,
          },
          stepSize: 200,
          beginAtZero: true,
        },
      },
      x: {
        border: {
          color: '#fff',
        },
        grid: {
          display: true,
          borderDash: [5, 5],
          color: 'rgba(255, 255, 255, 0)',
        },
        ticks: {
          color: '#fff',
          font: {
            size: 10,
          },
          stepSize: 1,
          beginAtZero: true,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  }
  const getNestedValue = (obj, keys) => {
    return keys.reduce((acc, key) => acc && acc[key], obj)
  }

  const transformKeys = (keys) => {
    return keys.map((key) => (key === 'farmNFT' ? 'farm' : key))
  }

  const data = {
    labels,
    datasets: checkedList?.map((dataItem) => {
      const transformedKeys = transformKeys(stateOpenKeys)
      const color = getRandomColor()
      const obj = {
        label: t(`item.${dataItem?.key}`),
        data: dataChart?.map((game, index) => {
          const value = getNestedValue(game?.firstData, transformedKeys)
          return value && value[dataItem?.value] != null
            ? value[dataItem?.value]
            : 0
        }),
        borderColor: color,
        backgroundColor: color,
      }

      return obj
    }),
  }

  useEffect(() => {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)
    const startDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000)
    const endDate = currentDate
    const params = {
      startDate: startDate.toISOString().slice(0, 10),
      endDate: endDate.toISOString().slice(0, 10),
    }

    if (date[0] !== '') {
      let temp = date[0].split('-')
      let temp1 = date[1].split('-')
      params.startDate = `${temp[0]}-${temp[1]}-${temp[2]}`
      params.endDate = `${temp1[0]}-${temp1[1]}-${temp1[2]}`
    }

    actions.getDashBoardGame(params, (res) => {
      setDataChart(res.data)
    })
  }, [date])

  const disabledDate = (current) => {
    return current && current > moment().endOf('day')
  }
  const onChangeDate = (values) => {
    setDate(values)
  }
  const handleSwitchChange = (checked, item) => {
    setCheckedList((prevList) => {
      if (checked) {
        if (prevList.length >= 5) {
          return [...prevList.slice(1), { key: item.key, value: item.value }]
        }
        return [...prevList, { key: item.key, value: item.value }]
      } else {
        return prevList.filter((i) => i.value !== item.value)
      }
    })

    setActiveMenuChild((prev) =>
      checked ? item : prev.key === item.key ? {} : prev,
    )
  }

  const handleMenuClickChild = (item) => {
    setActiveMenuChild(item)
  }

  const renderSwitches = (children) => {
    return children.map((item) => {
      if (item.children) {
        return (
          <Menu.SubMenu
            key={item.key}
            title={item.label}
            onTitleClick={() => handleMenuClickChild(item)}
          >
            {renderSwitches(item.children)}
          </Menu.SubMenu>
        )
      } else {
        // const isChecked = checkedList.includes(item.value)
        const isChecked = checkedList?.some((i) => i.value === item.value)

        return (
          <Menu.Item key={item.key} style={{ marginBottom: 10 }}>
            <Row>
              <Col span={18}>
                <span>{item.label}</span>
              </Col>
              <Col span={4}>
                <Switch
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  checked={isChecked}
                  onChange={(checked) => {
                    handleSwitchChange(checked, item)
                    if (checked) {
                      setActiveMenuChild(item)
                    }
                  }}
                />
              </Col>
            </Row>
          </Menu.Item>
        )
      }
    })
  }

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1,
    )
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey])
      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      )
      //test
      if (
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      ) {
        setCheckedList([])
      }
    } else {
      setStateOpenKeys(openKeys)
    }
  }
  return (
    <>
      <div>
        <Row gutter={24} className="equal-height-row-GameDashboard">
          <Col span={18} className="equal-height-col-GameDashboard">
            <Card
              title=""
              extra={
                <div className="flex gap-4 items-center">
                  <Popover
                    className="w-full"
                    trigger="click"
                    placement="bottom"
                    arrow={false}
                  >
                    <Button className="!bg-[#00113D] border__grey hover:!border-[#40A9FF] h-[32.19px] !w-[210px]"></Button>
                  </Popover>
                  <RangePicker
                    className="h-full max-w-[256px]"
                    onChange={(values, dateString) => onChangeDate(dateString)}
                    disabledDate={disabledDate}
                  />
                </div>
              }
            >
              <div>{t(`gameDashboard.unit`)}</div>
              <Line options={options} data={data} />
            </Card>
          </Col>
          <Col span={6} className="equal-height-col-GameDashboard">
            <Card className="gameFilter" title="">
              <Row>
                <Col span={24}>
                  <div
                    className="gameMenu"
                    style={{
                      overflowY: 'auto',
                      minHeight: '700px',
                      maxHeight: '746px',
                    }}
                  >
                    <Menu
                      mode="inline"
                      onOpenChange={onOpenChange}
                      openKeys={stateOpenKeys}
                    >
                      {itemsGameDashboard.map((item) => (
                        <Menu.SubMenu key={item.key} title={item.label}>
                          {renderSwitches(item.children)}
                        </Menu.SubMenu>
                      ))}
                    </Menu>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default GameChart
