export const GET_LIST_BANNED_USER_START = 'GET_LIST_BANNED_USER_START'
export const GET_LIST_BANNED_USER_SUCCESS = 'GET_LIST_BANNED_USER_SUCCESS'
export const GET_LIST_BANNED_USER_FAILED = 'GET_LIST_BANNED_USER_FAILED'

export const GET_LIST_ADD_BANNED_USER_START = 'GET_LIST_ADD_BANNED_USER_START'
export const GET_LIST_ADD_BANNED_USER_SUCCESS =
  'GET_LIST_ADD_BANNED_USER_SUCCESS'
export const GET_LIST_ADD_BANNED_USER_FAILED = 'GET_LIST_ADD_BANNED_USER_FAILED'

export const CREATE_BANNED_USER_START = 'CREATE_BANNED_USER_START'
export const CREATE_BANNED_USER_SUCCESS = 'CREATE_BANNED_USER_SUCCESS'
export const CREATE_BANNED_USER_FAILED = 'CREATE_BANNED_USER_FAILED'

export const DELETE_BANNED_USER_START = 'DELETE_BANNED_USER_START'
export const DELETE_BANNED_USER_SUCCESS = 'DELETE_BANNED_USER_SUCCESS'
export const DELETE_BANNED_USER_FAILED = 'DELETE_BANNED_USER_FAILED'

//-------------------------banned user------------------------//

/**
 * [1]
 * get list banned user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListBannedUserLists(payload, onSuccess, onError) {
  return {
    type: GET_LIST_BANNED_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list banned user success action
 * @param {*} payload
 * @returns {object}
 */
export function getListBannedUserListsSuccess(payload) {
  return {
    type: GET_LIST_BANNED_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get list banned user failed action
 * @returns {object}
 */
export function getListBannedUserListsFailed() {
  return {
    type: GET_LIST_BANNED_USER_FAILED,
  }
}

/**
 * [2]
 * get list add banned user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListAddBannedUserLists(payload, onSuccess, onError) {
  return {
    type: GET_LIST_ADD_BANNED_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list add banned user success action
 * @param {*} payload
 * @returns {object}
 */
export function getListAddBannedUserListsSuccess(payload) {
  return {
    type: GET_LIST_ADD_BANNED_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get list add banned user failed action
 * @returns {object}
 */
export function getListAddBannedUserListsFailed() {
  return {
    type: GET_LIST_ADD_BANNED_USER_FAILED,
  }
}

/**
 * [3]
 * create banned user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createBannedUserLists(payload, onSuccess, onError) {
  return {
    type: CREATE_BANNED_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * create banned user success action
 * @param {*} payload
 * @returns {object}
 */
export function createBannedUserListsSuccess(payload) {
  return {
    type: CREATE_BANNED_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * create banned user failed action
 * @returns {object}
 */
export function createBannedUserListsFailed() {
  return {
    type: CREATE_BANNED_USER_FAILED,
  }
}

/**
 * [4]
 * delete banned user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function deleteBannedUserLists(payload, onSuccess, onError) {
  return {
    type: DELETE_BANNED_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * delete banned user success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteBannedUserListsSuccess(payload) {
  return {
    type: DELETE_BANNED_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * delete banned user failed action
 * @returns {object}
 */
export function deleteBannedUserListsFailed() {
  return {
    type: DELETE_BANNED_USER_FAILED,
  }
}

export default {
  getListBannedUserLists,
  getListBannedUserListsSuccess,
  getListBannedUserListsFailed,

  getListAddBannedUserLists,
  getListAddBannedUserListsSuccess,
  getListAddBannedUserListsFailed,

  createBannedUserLists,
  createBannedUserListsSuccess,
  createBannedUserListsFailed,

  deleteBannedUserLists,
  deleteBannedUserListsSuccess,
  deleteBannedUserListsFailed,
}
