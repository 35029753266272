import { call, put, takeLatest } from 'redux-saga/effects'
import {
  createBannedUserListsSuccess,
  createBannedUserListsFailed,
  CREATE_BANNED_USER_START,
} from '../../actions/chat'
import { api } from '~/services/api'

/**
 * create banned user api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const createBannedUserListsApi = (props) => {
  var uri = `/chat-management/banned-user`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doCreateBannedUserLists(action) {
  try {
    const response = yield call(createBannedUserListsApi, action?.payload)
    yield put(createBannedUserListsSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(createBannedUserListsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch create banned user
 */
export default function* watchCreateBannedUserLists() {
  yield takeLatest(CREATE_BANNED_USER_START, doCreateBannedUserLists)
}
