import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getListAddBannedUserListsSuccess,
  getListAddBannedUserListsFailed,
  GET_LIST_ADD_BANNED_USER_START,
} from '../../actions/chat'
import { api } from '~/services/api'

/**
 * get list add bannedUser api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListAddBannedUserListsApi = (props) => {
  var uri = `/chat-management/banned-user/users`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetAddBannedUserLists(action) {
  try {
    const response = yield call(getListAddBannedUserListsApi, action?.payload)
    yield put(getListAddBannedUserListsSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(getListAddBannedUserListsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list add bannedUser
 */
export default function* watchGetAddBannedUserLists() {
  yield takeLatest(GET_LIST_ADD_BANNED_USER_START, doGetAddBannedUserLists)
}
