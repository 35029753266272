import PropTypes from 'prop-types'
import { Form, Modal, Row, Col, Input, Tooltip, Spin, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import { useQueryState } from '~/common/hooks/useQueryState'
import { useUser } from '~/views/app/redux/hooks/useUser'
import {
  converRole,
  converUserRankUser,
  dataGuildLevel,
  getEndOfMonthUTC,
  getStartOfMonthUTC,
} from '~/common/constants'
import moment from 'moment'
import { formatNumber } from '~/helpers/common'
import Profile from '../../ManagementUsers/Users/form/profile'
import Achievement from '../../ManagementUsers/Users/form/achievement'
function UserGuild(props) {
  const { t } = useTranslation()
  const {
    isShowModal,
    onClose,
    data,
    currentCycle,
    listPool,
    listUserByWallet,
  } = props

  const [formSearch] = Form.useForm()
  const [userWallet, setUserWallet] = useState([])
  const [sortListWallet, setSortListWallet] = useState([])
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    user: {},
    address: [],
  })
  const [modalFormAchievement, setModalFormAchievement] = useState({
    id: null,
    isOpenModal: false,
    address: null,
  })
  const onShowModalFormAchievement = (id, address) => {
    setModalFormAchievement({
      id,
      isOpenModal: true,
      address: address,
    })
  }
  const onShowModalForm = (id, user, address) => {
    setModalForm({
      id,
      isOpenModal: true,
      user: user,
      address: address,
    })
  }
  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      user: {},
      address: [],
    })
  }
  const onCloseModalFormAchievement = () => {
    setModalFormAchievement({
      id: null,
      isOpenModal: false,
      user: {},
    })
  }
  const [onlineUsers, setOnlineUsers] = useState([])
  const validWallets = data?.guild_member.map((item) => item.user_wallet)

  const filteredData = listUserByWallet.filter((user) =>
    validWallets.includes(user.address_wallet),
  )

  const { page, pageSize } = useQueryState()
  const {
    actions,
    data: { isLoading },
  } = useUser()

  useEffect(() => {
    const userResult = data?.guild_member
      ? data.guild_member.map((item) => item.user_wallet)
      : []
    setUserWallet(userResult)
  }, [data])
  useEffect(() => {
    if (data && userWallet && userWallet.length > 0) {
      const paramsScore = {
        pageSize: pageSize,
        page,
        user_wallets: userWallet,
      }
      actions.getScoreByWallet(paramsScore)
    }
  }, [data, userWallet, pageSize, page])

  useEffect(() => {
    // Sort listUserByWallet based on userWallet order
    const sortedList = [...filteredData].sort((a, b) => {
      const indexA = userWallet.indexOf(a.address_wallet)
      const indexB = userWallet.indexOf(b.address_wallet)
      return indexA - indexB
    })

    const mergedList = sortedList.map((user) => {
      const standing = data?.guild_member?.find(
        (item) => item.user_wallet === user.address_wallet,
      )
      return standing
        ? {
            ...user,
            member_role: standing.member_role || 0,
          }
        : user // If no matching standing is found, return the user as is
    })
    if (userWallet.length > 0) {
      setSortListWallet(
        mergedList.sort((a, b) => a.member_role - b.member_role),
      )
    } else {
      setSortListWallet([])
    }
  }, [listUserByWallet, userWallet, data])
  const handleClose = () => {
    onClose()
  }
  const handleSearch = (values) => {
    const keyword = values?.keyword?.toLowerCase() || ''
    if (!keyword) {
      const mergedList = filteredData.map((user) => {
        const standing = data?.guild_member?.find(
          (item) => item.user_wallet === user.address_wallet,
        )
        return standing
          ? {
              ...user,
              member_role: standing.member_role || 0,
            }
          : user
      })

      setSortListWallet(
        mergedList.sort((a, b) => a.member_role - b.member_role),
      )
      return
    }
    const filteredWallets = filteredData.filter((user) =>
      user.user_name?.toLowerCase().includes(keyword),
    )
    setSortListWallet(
      filteredWallets.sort((a, b) => a.member_role - b.member_role),
    )
  }

  const getLastActive = (date, userId) => {
    const isOnline = moment(date).isAfter(moment().subtract(1, 'minute'))
    const lastLoginTime = moment(date).fromNow() // Time since last active
    if (isOnline && !onlineUsers.includes(userId)) {
      setOnlineUsers((prev) => [...prev, userId])
    }
    return isOnline
      ? 'Online'
      : moment(date).isAfter(moment().subtract(24, 'hours'))
      ? `Online \n ${lastLoginTime}`
      : `Online ${moment(date).calendar(null, {
          sameDay: '[Today]',
          lastDay: '[Yesterday]',
          lastWeek: '[Last] dddd',
          sameElse: 'DD/MM/YYYY',
        })}`
  }
  useEffect(() => {
    const updatedOnlineUsers = sortListWallet
      .filter((user) =>
        moment(user.last_active).isAfter(moment().subtract(1, 'minute')),
      )
      .map((user) => user.user_id)

    setOnlineUsers(updatedOnlineUsers)
  }, [sortListWallet])
  const filterDataGuildMember = data?.guild_member?.filter(
    (member, index, self) =>
      index === self.findIndex((m) => m.user_wallet === member.user_wallet),
  )
  const getUnixRange = (data) => {
    const times = data?.flatMap(({ timeStart, timeEnd }) => [
      timeStart,
      timeEnd,
    ])
    const fromCycleTotal = Math.min(...times)
    const toCycleTotal = Math.max(...times)
    return { fromCycleTotal, toCycleTotal }
  }

  const { fromCycleTotal, toCycleTotal } = getUnixRange(listPool)

  const [scoreCycle, setScoreCycle] = useState([])
  const [scoreMonth, setScoreMonth] = useState([])
  const [scoreTotal, setScoreTotal] = useState([])

  useEffect(() => {
    const paramScoreSnapShotGuildMonth = {
      score_type: 'SECTOR',
      from_date: getStartOfMonthUTC(),
      to_date: getEndOfMonthUTC(),
    }
    actions.getScoreSnapShotAddress(paramScoreSnapShotGuildMonth, (res) => {
      if (res) {
        setScoreMonth(res?.data?.score_snapshot)
      }
    })
  }, [scoreCycle])
  useEffect(() => {
    const paramScoreSnapShotGuildCycle = {
      score_type: 'SECTOR',
      from_date: currentCycle?.timeStart,
      to_date: currentCycle?.timeEnd,
    }
    actions.getScoreSnapShotAddress(paramScoreSnapShotGuildCycle, (res) => {
      if (res) {
        setScoreCycle(res?.data?.score_snapshot)
      }
    })
  }, [scoreTotal, currentCycle])
  useEffect(() => {
    const paramScoreSnapShotGuildTotal = {
      score_type: 'SECTOR',
      from_date: fromCycleTotal,
      to_date: toCycleTotal,
    }
    actions.getScoreSnapShotAddress(paramScoreSnapShotGuildTotal, (res) => {
      if (res) {
        setScoreTotal(res?.data?.score_snapshot)
      }
    })
  }, [fromCycleTotal, toCycleTotal])

  const sumScoresByUser = (data) => {
    return data?.reduce((acc, { user, score }) => {
      const userAddress = user.toLowerCase()
      acc[userAddress] = (acc[userAddress] || 0) + Math.round(score)
      return acc
    }, {})
  }

  // Calculate scores for each data set
  const sumScoresByUserCycle = sumScoresByUser(scoreCycle)
  const sumScoresByUserMonth = sumScoresByUser(scoreMonth)
  const sumScoresByUserTotal = sumScoresByUser(scoreTotal)

  return (
    <Modal
      title={<>{t('managementGuilds.form.userGuild')}</>}
      onCancel={handleClose}
      open={isShowModal}
      width={1000}
      centered
      destroyOnClose
      footer={null}
    >
      <div className="flex sm:justify-end w-full">
        <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
          <Form
            onFinish={handleSearch}
            className="w-full sm:w-auto"
            form={formSearch}
            translate="yes"
            autoComplete="off"
            size="middle"
            validateTrigger={['onBlur', 'onChange']}
            requiredMark={false}
          >
            <Form.Item
              className="w-full"
              label={
                <span className="font__M__plus font-bold">
                  {t('common.search')}
                </span>
              }
              name="keyword"
              labelAlign="left"
            >
              <Input className="w-full" placeholder={t('common.search')} />
            </Form.Item>
          </Form>
        </div>
      </div>

      <div
        style={{
          background: '#1890ff',
          borderRadius: '8px',
          boxShadow: '0 0 6px 0 #2ca0ffcc',
          fontFamily: 'Rexliafree',
        }}
      >
        {/* Title */}
        <Row className="p-[12px]">
          <Col
            span={7}
            className="flex justify-center items-center  text-[16px] ml-[-30px]"
          >
            Member {sortListWallet?.length}/
            {dataGuildLevel(data?.guild_level)?.maxMembers}
          </Col>
          <Col
            span={2}
            className="flex justify-center items-center text-[16px]  "
          >
            Rank
          </Col>
          <Col
            span={5}
            className="flex justify-center items-center text-[16px] "
          >
            Contribution
          </Col>
          <Col
            span={4}
            className="flex justify-center items-center text-[16px] "
          >
            Role
          </Col>
          <Col
            span={3}
            className="flex justify-center items-center  text-[16px]"
          >
            Online {onlineUsers?.length}/{filterDataGuildMember?.length}
          </Col>
          <Col
            span={3}
            className="flex justify-center items-center  text-[16px]"
          >
            Action
          </Col>
        </Row>

        {/* Content */}
        <Spin spinning={isLoading}>
          {sortListWallet?.map((item, index) => (
            <div
              key={index}
              className="guildInfo"
              style={{
                background: '#0D2758',
                borderRadius:
                  index === sortListWallet.length - 1 ? '0 0 8px 8px' : '0',

                maxHeight: '465px',
                overflowY: 'auto',
                borderBottom: '1px solid rgba(36, 169, 250, 0.3)',
              }}
            >
              <Row
                style={{
                  borderRadius: '8px',
                  position: 'relative',
                  margin: '8px 0',
                }}
              >
                {item?.member_role === 0 && (
                  <img
                    className="absolute left-[15px] bottom-[28px] w-[25px] h-[25px]"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/guildMaster.svg`}
                    alt=""
                  />
                )}
                {item?.member_role === 1 && (
                  <img
                    className="absolute left-[15px] bottom-[28px] w-[25px] h-[25px]"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/subGuild.svg`}
                    alt=""
                  />
                )}

                <Col
                  span={7}
                  className=" flex justify-center items-center text-[16px] ml-[-30px]"
                >
                  <img
                    className=" w-[30px] h-[30px] mr-2"
                    // src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                    src={item?.avatar}
                    alt=""
                  />
                  <Tooltip title={item?.user_name}>
                    <span className="max-w-[80px] overflow-hidden whitespace-nowrap text-ellipsis">
                      {item?.user_name}
                    </span>
                  </Tooltip>
                </Col>
                <Col
                  span={2}
                  className="flex justify-center items-center text-[16px] "
                >
                  {converUserRankUser(item?.player_rank)}
                </Col>
                <Col
                  span={5}
                  className="flex justify-center items-center text-[16px] p-[5px] "
                >
                  <Row>
                    <Col span={8} className="flex justify-center items-center ">
                      <img
                        className="w-[20px] h-[20px] mr-3 "
                        src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                        alt=""
                      />
                      <div>
                        <div>Total</div>
                        <div>Month</div>
                        <div>Cycle</div>
                      </div>
                    </Col>
                    <Col span={16} className="flex justify-end items-center ">
                      <div className="flex items-center flex-col ">
                        <div>
                          {formatNumber(
                            sumScoresByUserTotal[item.user_id.toLowerCase()] ||
                              0,
                          )}
                        </div>
                        <div>
                          {formatNumber(
                            sumScoresByUserMonth[item.user_id.toLowerCase()] ||
                              0,
                          )}
                        </div>
                        <div>
                          {formatNumber(
                            sumScoresByUserCycle[item.user_id.toLowerCase()] ||
                              0,
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={4}
                  className="flex justify-center items-center text-[16px] "
                >
                  {converRole(item?.member_role)}
                </Col>
                <Col
                  span={3}
                  className={`flex justify-center items-center text-[16px] ${
                    moment(item?.last_active).isAfter(
                      moment().subtract(1, 'minute'),
                    )
                      ? 'text-[#2FFF39]'
                      : ''
                  }`}
                >
                  {getLastActive(item?.last_active, item?.user_id)}
                </Col>
                <Col
                  span={3}
                  className="flex justify-center items-center gap-2 "
                >
                  <Button
                    type="ghost"
                    loading={false}
                    onClick={() =>
                      onShowModalForm(item?._id, item, item.address_wallet)
                    }
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M17.7337 15.6133C17.3127 14.6159 16.7016 13.7099 15.9346 12.9459C15.17 12.1796 14.2642 11.5687 13.2672 11.1468C13.2583 11.1423 13.2494 11.1401 13.2404 11.1356C14.6311 10.1311 15.5351 8.49498 15.5351 6.649C15.5351 3.59096 13.0574 1.11328 9.99936 1.11328C6.94132 1.11328 4.46364 3.59096 4.46364 6.649C4.46364 8.49498 5.36766 10.1311 6.75829 11.1378C6.74936 11.1423 6.74043 11.1445 6.7315 11.149C5.7315 11.5709 4.83418 12.1758 4.06409 12.9481C3.29782 13.7128 2.68687 14.6186 2.26498 15.6155C1.85052 16.5915 1.627 17.6379 1.6065 18.6981C1.60591 18.7219 1.61008 18.7456 1.61879 18.7678C1.6275 18.79 1.64056 18.8102 1.6572 18.8273C1.67384 18.8443 1.69373 18.8579 1.71569 18.8671C1.73765 18.8764 1.76124 18.8811 1.78507 18.8811H3.12436C3.22257 18.8811 3.3007 18.803 3.30293 18.707C3.34757 16.9838 4.03954 15.37 5.26275 14.1468C6.52838 12.8811 8.20918 12.1847 9.99936 12.1847C11.7895 12.1847 13.4703 12.8811 14.736 14.1468C15.9592 15.37 16.6511 16.9838 16.6958 18.707C16.698 18.8052 16.7761 18.8811 16.8744 18.8811H18.2136C18.2375 18.8811 18.2611 18.8764 18.283 18.8671C18.305 18.8579 18.3249 18.8443 18.3415 18.8273C18.3582 18.8102 18.3712 18.79 18.3799 18.7678C18.3886 18.7456 18.3928 18.7219 18.3922 18.6981C18.3699 17.6311 18.1489 16.5932 17.7337 15.6133ZM9.99936 10.4883C8.97481 10.4883 8.01052 10.0887 7.28507 9.36328C6.55963 8.63784 6.16007 7.67355 6.16007 6.649C6.16007 5.62444 6.55963 4.66016 7.28507 3.93471C8.01052 3.20926 8.97481 2.80971 9.99936 2.80971C11.0239 2.80971 11.9882 3.20926 12.7136 3.93471C13.4391 4.66016 13.8386 5.62444 13.8386 6.649C13.8386 7.67355 13.4391 8.63784 12.7136 9.36328C11.9882 10.0887 11.0239 10.4883 9.99936 10.4883Z"
                          fill="#40A9FF"
                        />
                      </svg>
                    }
                  />
                  <Button
                    type="ghost"
                    loading={false}
                    onClick={() => {
                      onShowModalFormAchievement(item?._id, item.address_wallet)
                    }}
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M18.6516 4.7386L14.3078 7.41494L10.1426 1.85913C10.1259 1.83677 10.1042 1.81862 10.0792 1.80612C10.0542 1.79362 10.0266 1.78711 9.99867 1.78711C9.97073 1.78711 9.94317 1.79362 9.91818 1.80612C9.89319 1.81862 9.87146 1.83677 9.8547 1.85913L5.69175 7.41494L1.34577 4.7386C1.21854 4.66047 1.05336 4.76538 1.07345 4.91494L2.77881 17.8748C2.80336 18.0511 2.95515 18.1873 3.13595 18.1873H16.8659C17.0444 18.1873 17.1985 18.0533 17.2208 17.8748L18.9261 4.91494C18.944 4.76538 18.781 4.66047 18.6516 4.7386ZM15.8391 16.6605H4.15827L2.95738 7.52208L6.07791 9.44396L9.99979 4.21181L13.9217 9.44396L17.0422 7.52208L15.8391 16.6605ZM9.99979 9.92833C8.61363 9.92833 7.4864 11.0556 7.4864 12.4417C7.4864 13.8279 8.61363 14.9551 9.99979 14.9551C11.386 14.9551 12.5132 13.8279 12.5132 12.4417C12.5132 11.0556 11.386 9.92833 9.99979 9.92833ZM9.99979 13.5198C9.40604 13.5198 8.9239 13.0377 8.9239 12.4417C8.9239 11.848 9.40604 11.3636 9.99979 11.3636C10.5935 11.3636 11.0757 11.8457 11.0757 12.4417C11.0757 13.0355 10.5935 13.5198 9.99979 13.5198Z"
                          fill="#FAAD14"
                        />
                      </svg>
                    }
                  />
                </Col>
              </Row>
            </div>
          ))}
        </Spin>
      </div>
      {modalForm.isOpenModal && (
        <Profile
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          id={modalForm.id}
          user={modalForm?.user}
          address={modalForm?.address}
          currentCycle={currentCycle}
        />
      )}
      {modalFormAchievement.isOpenModal && (
        <Achievement
          isShowModal={modalFormAchievement.isOpenModal}
          onClose={onCloseModalFormAchievement}
          id={modalFormAchievement.id}
          address={modalFormAchievement?.address}
          currentCycle={currentCycle}
        />
      )}
    </Modal>
  )
}

UserGuild.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default UserGuild
