import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu, Grid } from 'antd'
import navigationConfig from '~/helpers/navigation-config'
import { connect } from 'react-redux'
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from '~/common/constants/ThemeConstant'
import utils from '~/utils'
import { onMobileNavToggle } from '~/common/theme/redux/actions'
// import { ROUTE } from '~/views/app/routes/config'
import IntlMessage from '~/components/util-components/IntlMessage'
import Icon from '~/components/util-components/Icon'
import { ROUTE } from '~/views/app/routes/config'

const { SubMenu } = Menu
const { useBreakpoint } = Grid

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={`menu.${localeKey}`} /> : localeKey.toString()

const setDefaultOpen = (key) => {
  let keyList = []
  let keyString = ''
  if (key) {
    const arr = key.split('-')
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index]
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`)
      keyList.push(keyString)
    }
  }
  return keyList
}

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    navCollapsed,
  } = props
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false)
    }
  }
  const location = useLocation()

  return (
    <div
      className="site-drawer-render-in-current-wrapper"
      style={{ marginBottom: '100px' }}
    >
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
        mode="inline"
        style={{ height: '100%', borderRight: 0 }}
        selectedKeys={[location.pathname]}
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={setDefaultOpen(
          [
            ROUTE.MARKETPLACE_DASHBOARD.PATH,
            ROUTE.SHOP_DASHBOARD.PATH,
            ROUTE.GAME_DASHBOARD.PATH,
          ].includes(location.pathname)
            ? ROUTE.HOME.PATH
            : [
                ROUTE.HASHCHIP_REDEEMED.PATH,
                ROUTE.RANKING_REWARDS.PATH,
                ROUTE.RANKING_POOL.PATH,
              ].includes(location.pathname)
            ? ROUTE.MANAGEMENT_POOLS.PATH
            : [ROUTE.ADMINS.PATH, ROUTE.USERS.PATH].includes(location.pathname)
            ? ROUTE.MANAGEMENT_USERS.PATH
            : [
                ROUTE.SPONSOR_TOURNAMENT.PATH,
                ROUTE.SPONSOR_WHITELIST.PATH,
                ROUTE.SPONSOR_BLACKLIST.PATH,
              ].includes(location.pathname)
            ? ROUTE.MANAGEMENT_SPONSOR_TOURNAMENT.PATH
            : [ROUTE.BANNER_USER.PATH].includes(location.pathname)
            ? ROUTE.MANAGEMENT_CHAT.PATH
            : '',
        )}
        className={hideGroupTitle ? 'hide-group-title' : ''}
      >
        {navigationConfig.map((menu) =>
          menu.submenu.length > 0 ? (
            <SubMenu
              key={menu.path}
              title={setLocale(localization, menu.title)}
              icon={<Icon type={menu.icon} />}
            >
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <Menu.ItemGroup
                    icon={<Icon type={subMenuFirst?.icon} />}
                    key={subMenuFirst.path}
                    title={setLocale(localization, subMenuFirst.title)}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.path}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>
                          {setLocale(localization, subMenuSecond.title)}
                        </span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    ))}
                  </Menu.ItemGroup>
                ) : (
                  <Menu.Item
                    key={subMenuFirst.path}
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                  >
                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                ),
              )}
            </SubMenu>
          ) : (
            <Menu.Item
              className={`${
                menu?.path === ROUTE.MANAGEMENT_COLLABORATION_NFT.PATH &&
                !navCollapsed
                  ? 'menuLength'
                  : ''
              }`}
              style={{
                lineHeight:
                  menu?.path === ROUTE.MANAGEMENT_COLLABORATION_NFT.PATH
                    ? '20px'
                    : 'auto',
              }}
              key={menu.path}
              icon={
                menu?.iconConfig ? (
                  menu?.iconConfig()
                ) : (
                  <Icon type={menu?.icon} />
                )
              }
            >
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? (
                <Link onClick={() => closeMobileNav()} to={menu.path} />
              ) : null}
            </Menu.Item>
          ),
        )}
      </Menu>
    </div>
  )
}

const TopNavContent = (props) => {
  const { topNavColor, localization } = props
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.path}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {/* @TODO: Filter role if it necessary */}
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.path}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.path}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.path}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              ),
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.path}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        ),
      )}
    </Menu>
  )
}

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  )
}

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme
  return { sideNavTheme, topNavColor }
}

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent)
