import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Form, notification, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import configs from '~/configs'
import { NOTIFICATION_DURATION } from '~/common/constants'
import { DeleteFilled } from '@ant-design/icons'
import DeleteBannedUserList from '~/common/hooks/useDeleteBannedUser'
import { useChats } from '~/views/app/redux/hooks/useChat'
import { useQueryState } from '~/common/hooks/useQueryState'
import TimeHelper from '~/helpers/time-helper'

const BannedUser = () => {
  const { t } = useTranslation()
  const [formSearch] = Form.useForm()
  const [isModalOpenDelete, setIsModalOpenDelete] = useState({
    isOpenModal: false,
    user: {},
  })

  const {
    actions,
    data: {
      listBannedUser,
      isLoadingGetListBannedUserList,
      listAddBannedUserList,
      total,
    },
  } = useChats()

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  const showModalDelete = (content, data) => {
    setIsModalOpenDelete({
      content: content,
      data: data,
      isOpenModal: true,
    })
  }
  const handleCancelDelete = () => {
    setIsModalOpenDelete({
      content: null,
      data: null,
      isOpenModal: false,
    })
  }
  const onRemove = (data) => {
    showModalDelete(t('cancel_modal.question', { question: '' }), data)
  }
  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword])
  const columns = [
    {
      title: t('common.no'),
      dataIndex: 'key',
      key: 'key',
      render: (data) => {
        return data
      },
    },
    {
      title: t('managementUsers.form.name'),
      dataIndex: 'user_name',
      key: 'user_name',
      render: (user_name, data) => {
        return <div className="">{data?.user_id?.user_name}</div>
      },
    },
    {
      title: t('managementUsers.form.walletAddress'),
      dataIndex: 'address_wallet',
      key: 'address_wallet',
      render: (address_wallet, data) => {
        let link = `${configs.NETWORK.scan}address/${data?.user_id?.address_wallet}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={data?.user_id?.address_wallet}
            style={{ color: '#40A9FF' }}
          >
            {data?.user_id?.address_wallet}
          </a>
        )
      },
    },
    {
      title: t('managementUsers.form.createdAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => {
        return TimeHelper(updatedAt)
      },
    },
    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={<DeleteFilled style={{ color: '#FF4D4F' }} />}
              onClick={() => {
                onRemove(data)
              }}
            />
          </Space>
        )
      },
    },
  ]

  //Refresh data
  const refreshData = () => {
    const params = {
      pageSize: pageSize,
      page,
    }
    actions.getListBannedUserLists(params)
    actions.getListAddBannedUserLists()
  }
  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword])

  const generateItemList = (listAddBannedUserList) =>
    listAddBannedUserList?.map((item) => ({
      key: item?._id,
      label: (
        <Row>
          <Col span={24}>Name: {item?.user_name}</Col>
          <Col span={24}>Wallet address: {item?.address_wallet}</Col>
        </Row>
      ),
      value: item?.address_wallet,
      name: item?.user_name,
    }))

  const handleAddUserBlackList = async (value) => {
    const params = {
      wallet_address: value,
    }
    try {
      await actions.createBannedUserLists(params)
      setTimeout(() => {
        refreshData()
        formSearch.resetFields()
        notification.success({
          message: t('common.message.createUserSuccessfully'),
          placement: 'topRight',
          duration: NOTIFICATION_DURATION,
        })
      }, 500)
    } catch (error) {
      //eslint-disable-next-line no-console
      console.log(error)
    }
  }
  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Form
            onFinish={handleSearch}
            className="w-[80%]"
            form={formSearch}
            autoComplete="off"
            size="middle"
            validateTrigger={['onBlur', 'onChange']}
            requiredMark={false}
          >
            <Form.Item
              className="w-full"
              label={
                <span className="font__M__plus font-bold">
                  {t('common.add')}
                </span>
              }
              name="keyword"
              labelAlign="left"
            >
              <Select
                className="w-full addUserList"
                onChange={handleAddUserBlackList}
                options={generateItemList(listAddBannedUserList)}
                placeholder={t('common.add')}
                showSearch
                filterOption={(input, option) => {
                  const nameMatch = option?.name
                    ?.toLowerCase()
                    .includes(input?.toLowerCase())
                  const valueMatch = option?.value
                    ?.toLowerCase()
                    .includes(input?.toLowerCase())
                  return nameMatch || valueMatch
                }}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-center"></div>
          </div>
        </Col>
      </Row>

      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={listBannedUser}
        loading={isLoadingGetListBannedUserList}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: total,
          keyword,
          setKeyword,
        }}
      />
      <DeleteBannedUserList
        action={{
          isModalOpen: isModalOpenDelete.isOpenModal,
          handleCancel: handleCancelDelete,
        }}
        refreshData={refreshData}
        dataBannedUserList={isModalOpenDelete.data}
        t={t}
      />
    </div>
  )
}

export default BannedUser
